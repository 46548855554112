/* THIS FILE IS AUTO-GENERATED BY `scripts/load-svg-icons.js`. DO NOT EDIT MANUALLY! */
import fill_addapprover from "./fill/add-approver.svg";
import fill_approved from "./fill/approved.svg";
import fill_automation from "./fill/automation.svg";
import fill_badge from "./fill/badge.svg";
import fill_check from "./fill/check.svg";
import fill_down from "./fill/down.svg";
import fill_draft from "./fill/draft.svg";
import fill_ellipse from "./fill/ellipse.svg";
import fill_inreview from "./fill/in-review.svg";
import fill_links from "./fill/links.svg";
import fill_loader_refresh from "./fill/loader_refresh.svg";
import fill_quantivelogowhite from "./fill/quantive-logo-white.svg";
import fill_ui1_checksimple from "./fill/ui-1_check-simple.svg";
import fill_ui1_simpleremove from "./fill/ui-1_simple-remove.svg";
import fill_ui2_alert from "./fill/ui-2_alert-.svg";
import fill_ui2_hourglass from "./fill/ui-2_hourglass.svg";
import fill_ui2_linkbroken70 from "./fill/ui-2_link-broken-70.svg";
import fill_up from "./fill/up.svg";
import outline_arrows1_refresh68 from "./outline/arrows-1_refresh-68.svg";
import outline_automation from "./outline/automation.svg";
import outline_bad from "./outline/bad.svg";
import outline_business_award48 from "./outline/business_award-48.svg";
import outline_business_books from "./outline/business_books.svg";
import outline_business_bulb61 from "./outline/business_bulb-61.svg";
import outline_business_businesscontact86 from "./outline/business_business-contact-86.svg";
import outline_business_businesscontact89 from "./outline/business_business-contact-89.svg";
import outline_business_businessman03 from "./outline/business_businessman-03.svg";
import outline_business_chartpie35 from "./outline/business_chart-pie-35.svg";
import outline_business_coins from "./outline/business_coins.svg";
import outline_business_factory from "./outline/business_factory.svg";
import outline_business_hammer from "./outline/business_hammer.svg";
import outline_business_hierarchy55 from "./outline/business_hierarchy-55.svg";
import outline_business_moneycoins from "./outline/business_money-coins.svg";
import outline_business_strategy from "./outline/business_strategy.svg";
import outline_business_wallet90 from "./outline/business_wallet-90.svg";
import outline_clothes_shoewoman from "./outline/clothes_shoe-woman.svg";
import outline_clothes_slacks13 from "./outline/clothes_slacks-13.svg";
import outline_clothes_tiebow from "./outline/clothes_tie-bow.svg";
import outline_clothes_vestsport from "./outline/clothes_vest-sport.svg";
import outline_design_bookbookmark from "./outline/design_book-bookmark.svg";
import outline_design_clone from "./outline/design_clone.svg";
import outline_design_codeeditor from "./outline/design_code-editor.svg";
import outline_design_code from "./outline/design_code.svg";
import outline_design_crop from "./outline/design_crop.svg";
import outline_design_design from "./outline/design_design.svg";
import outline_design_mouse10 from "./outline/design_mouse-10.svg";
import outline_design_paint38 from "./outline/design_paint-38.svg";
import outline_design_wand99 from "./outline/design_wand-99.svg";
import outline_design_webdesign from "./outline/design_web-design.svg";
import outline_design_windowcode from "./outline/design_window-code.svg";
import outline_design_windowdev from "./outline/design_window-dev.svg";
import outline_design_windowparagraph from "./outline/design_window-paragraph.svg";
import outline_dragdrop from "./outline/drag-drop.svg";
import outline_dragicon from "./outline/drag-icon.svg";
import outline_education_atom from "./outline/education_atom.svg";
import outline_education_board51 from "./outline/education_board-51.svg";
import outline_education_hat from "./outline/education_hat.svg";
import outline_education_language from "./outline/education_language.svg";
import outline_emoticons_angry44 from "./outline/emoticons_angry-44.svg";
import outline_emoticons_cake from "./outline/emoticons_cake.svg";
import outline_emoticons_fist from "./outline/emoticons_fist.svg";
import outline_emoticons_like from "./outline/emoticons_like.svg";
import outline_emoticons_monster from "./outline/emoticons_monster.svg";
import outline_emoticons_nerd22 from "./outline/emoticons_nerd-22.svg";
import outline_emoticons_sunglasses48 from "./outline/emoticons_sunglasses-48.svg";
import outline_emoticons_virus from "./outline/emoticons_virus.svg";
import outline_files_folder19 from "./outline/files_folder-19.svg";
import outline_food_broccoli from "./outline/food_broccoli.svg";
import outline_food_chefhat from "./outline/food_chef-hat.svg";
import outline_food_chili from "./outline/food_chili.svg";
import outline_food_coffeelong from "./outline/food_coffee-long.svg";
import outline_food_crab from "./outline/food_crab.svg";
import outline_food_moka from "./outline/food_moka.svg";
import outline_food_muffin from "./outline/food_muffin.svg";
import outline_food_mug from "./outline/food_mug.svg";
import outline_furniture_bedside from "./outline/furniture_bed-side.svg";
import outline_good from "./outline/good.svg";
import outline_health_brain from "./outline/health_brain.svg";
import outline_health_weed from "./outline/health_weed.svg";
import outline_location_compass04 from "./outline/location_compass-04.svg";
import outline_location_explore from "./outline/location_explore.svg";
import outline_location_flagdiagonal33 from "./outline/location_flag-diagonal-33.svg";
import outline_location_positionmarker from "./outline/location_position-marker.svg";
import outline_media1_audio92 from "./outline/media-1_audio-92.svg";
import outline_media1_flash24 from "./outline/media-1_flash-24.svg";
import outline_media1_image02 from "./outline/media-1_image-02.svg";
import outline_media1_lens31 from "./outline/media-1_lens-31.svg";
import outline_media1_sport from "./outline/media-1_sport.svg";
import outline_media1_touch from "./outline/media-1_touch.svg";
import outline_media1_video67 from "./outline/media-1_video-67.svg";
import outline_media2_guitar from "./outline/media-2_guitar.svg";
import outline_neutral from "./outline/neutral.svg";
import outline_objects_alien29 from "./outline/objects_alien-29.svg";
import outline_objects_alien33 from "./outline/objects_alien-33.svg";
import outline_objects_baby from "./outline/objects_baby.svg";
import outline_objects_bear from "./outline/objects_bear.svg";
import outline_objects_binocular from "./outline/objects_binocular.svg";
import outline_objects_diamond from "./outline/objects_diamond.svg";
import outline_objects_leaf36 from "./outline/objects_leaf-36.svg";
import outline_objects_leaf38 from "./outline/objects_leaf-38.svg";
import outline_objects_planet from "./outline/objects_planet.svg";
import outline_objects_spaceship from "./outline/objects_spaceship.svg";
import outline_objects_support17 from "./outline/objects_support-17.svg";
import outline_shopping_box3d50 from "./outline/shopping_box-3d-50.svg";
import outline_shopping_creditcard from "./outline/shopping_credit-card.svg";
import outline_tech_computermonitor from "./outline/tech_computer-monitor.svg";
import outline_tech_mobilebutton from "./outline/tech_mobile-button.svg";
import outline_transportation_boatfront from "./outline/transportation_boat-front.svg";
import outline_transportation_moto from "./outline/transportation_moto.svg";
import outline_transportation_plane18 from "./outline/transportation_plane-18.svg";
import outline_travel_hut from "./outline/travel_hut.svg";
import outline_travel_shark from "./outline/travel_shark.svg";
import outline_travel_world from "./outline/travel_world.svg";
import outline_ui1_analytics88 from "./outline/ui-1_analytics-88.svg";
import outline_ui1_calendarcheck59 from "./outline/ui-1_calendar-check-59.svg";
import outline_ui1_dashboard30 from "./outline/ui-1_dashboard-30.svg";
import outline_ui1_database from "./outline/ui-1_database.svg";
import outline_ui1_eye17 from "./outline/ui-1_eye-17.svg";
import outline_ui1_home51 from "./outline/ui-1_home-51.svg";
import outline_ui1_send from "./outline/ui-1_send.svg";
import outline_ui1_settingsgear64 from "./outline/ui-1_settings-gear-64.svg";
import outline_ui2_cloud25 from "./outline/ui-2_cloud-25.svg";
import outline_ui2_favourite28 from "./outline/ui-2_favourite-28.svg";
import outline_ui2_favourite31 from "./outline/ui-2_favourite-31.svg";
import outline_ui2_lab from "./outline/ui-2_lab.svg";
import outline_ui2_like from "./outline/ui-2_like.svg";
import outline_users_multiple11 from "./outline/users_multiple-11.svg";
import outline_users_network from "./outline/users_network.svg";
import outline_weather_hurricane44 from "./outline/weather_hurricane-44.svg";
import outline_weather_moon from "./outline/weather_moon.svg";
import twotone_automations from "./twotone/automations.svg";

export const fillIcons = [
  fill_addapprover,
  fill_approved,
  fill_automation,
  fill_badge,
  fill_check,
  fill_down,
  fill_draft,
  fill_ellipse,
  fill_inreview,
  fill_links,
  fill_loader_refresh,
  fill_quantivelogowhite,
  fill_ui1_checksimple,
  fill_ui1_simpleremove,
  fill_ui2_alert,
  fill_ui2_hourglass,
  fill_ui2_linkbroken70,
  fill_up,
];

export const outlineIcons = [
  outline_arrows1_refresh68,
  outline_automation,
  outline_bad,
  outline_business_award48,
  outline_business_books,
  outline_business_bulb61,
  outline_business_businesscontact86,
  outline_business_businesscontact89,
  outline_business_businessman03,
  outline_business_chartpie35,
  outline_business_coins,
  outline_business_factory,
  outline_business_hammer,
  outline_business_hierarchy55,
  outline_business_moneycoins,
  outline_business_strategy,
  outline_business_wallet90,
  outline_clothes_shoewoman,
  outline_clothes_slacks13,
  outline_clothes_tiebow,
  outline_clothes_vestsport,
  outline_design_bookbookmark,
  outline_design_clone,
  outline_design_codeeditor,
  outline_design_code,
  outline_design_crop,
  outline_design_design,
  outline_design_mouse10,
  outline_design_paint38,
  outline_design_wand99,
  outline_design_webdesign,
  outline_design_windowcode,
  outline_design_windowdev,
  outline_design_windowparagraph,
  outline_dragdrop,
  outline_dragicon,
  outline_education_atom,
  outline_education_board51,
  outline_education_hat,
  outline_education_language,
  outline_emoticons_angry44,
  outline_emoticons_cake,
  outline_emoticons_fist,
  outline_emoticons_like,
  outline_emoticons_monster,
  outline_emoticons_nerd22,
  outline_emoticons_sunglasses48,
  outline_emoticons_virus,
  outline_files_folder19,
  outline_food_broccoli,
  outline_food_chefhat,
  outline_food_chili,
  outline_food_coffeelong,
  outline_food_crab,
  outline_food_moka,
  outline_food_muffin,
  outline_food_mug,
  outline_furniture_bedside,
  outline_good,
  outline_health_brain,
  outline_health_weed,
  outline_location_compass04,
  outline_location_explore,
  outline_location_flagdiagonal33,
  outline_location_positionmarker,
  outline_media1_audio92,
  outline_media1_flash24,
  outline_media1_image02,
  outline_media1_lens31,
  outline_media1_sport,
  outline_media1_touch,
  outline_media1_video67,
  outline_media2_guitar,
  outline_neutral,
  outline_objects_alien29,
  outline_objects_alien33,
  outline_objects_baby,
  outline_objects_bear,
  outline_objects_binocular,
  outline_objects_diamond,
  outline_objects_leaf36,
  outline_objects_leaf38,
  outline_objects_planet,
  outline_objects_spaceship,
  outline_objects_support17,
  outline_shopping_box3d50,
  outline_shopping_creditcard,
  outline_tech_computermonitor,
  outline_tech_mobilebutton,
  outline_transportation_boatfront,
  outline_transportation_moto,
  outline_transportation_plane18,
  outline_travel_hut,
  outline_travel_shark,
  outline_travel_world,
  outline_ui1_analytics88,
  outline_ui1_calendarcheck59,
  outline_ui1_dashboard30,
  outline_ui1_database,
  outline_ui1_eye17,
  outline_ui1_home51,
  outline_ui1_send,
  outline_ui1_settingsgear64,
  outline_ui2_cloud25,
  outline_ui2_favourite28,
  outline_ui2_favourite31,
  outline_ui2_lab,
  outline_ui2_like,
  outline_users_multiple11,
  outline_users_network,
  outline_weather_hurricane44,
  outline_weather_moon,
];

export const twotoneIcons = [twotone_automations];

export const resultsIcons = [...fillIcons, ...outlineIcons, ...twotoneIcons];
